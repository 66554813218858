import Vue from "vue";
import VueRouter from "vue-router";

import route1 from "./route_1";

Vue.use(VueRouter);

const routes = [...route1];

const router = new VueRouter({
  routes,
});

export default router;
