import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "./utils/axios"
import * as echarts from "echarts";
import Highchart from "highcharts/highcharts";
import HighchartsVue from 'highcharts-vue'
import highcharts3d from 'highcharts/highcharts-3d'
import 'amfe-flexible'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
highcharts3d(Highchart)
Vue.prototype.$Highchart = Highchart;
Vue.prototype.$echarts = echarts;
Vue.use(HighchartsVue)

Vue.config.productionTip = false;

Vue.filter("countFilter", (count) => {
  if (!count) {
    return 0
  }
  if (count - 1 > 999999) {
    return '999999+'
  }
  return count
})

Vue.filter("PvUvFilter", (count) => {
  if (count <= 10000) {
    return count
  }
  return (count/10000).toFixed(2) + "w"
})

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");