
<template>
  <div class="component_container">
    <div class="title_box">流水情况</div>
    <div class="num_box">
      <div class="name">总流水</div>
      <div class="num" v-for="(item, index) in totalFlow" :key="index">
        {{ item }}
      </div>
      <span>￥</span>
    </div>
    <div class="content_box">
      <div class="item">
        <img src="../assets/images/right/right1.png" alt="" />
        <div class="right">
          <div class="name">日均流水</div>
          <div class="num"><span>￥</span>{{ dayFlow }}</div>
        </div>
      </div>
      <div class="item">
        <img src="../assets/images/right/right2.png" alt="" />
        <div class="right">
          <div class="name">伙伴权益</div>
          <div class="num" style="color: #05999e">
            <span>￥</span>{{ commissionFlow }}
          </div>
        </div>
      </div>
      <div class="item">
        <img src="../assets/images/right/right3.png" alt="" />
        <div class="right">
          <div class="name">社区发展基金</div>
          <div class="num" style="color: #fcd773"><span>￥</span>{{ num }}</div>
        </div>
      </div>
    </div>
    <div class="echarts_box">
      <div id="echarts2" style="height: 100%"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CentetLeft1",
  props: {
    dataObj: {
      default: {},
    },
  },
  data() {
    return {
      totalFlow: "0000000",
      dayFlow: 0,
      commissionFlow: 0,
      num: 0,
    };
  },
  mounted() {
    this.getDataMethod();
  },
  methods: {
    getDataMethod() {
      if (Object.keys(this.dataObj).length == 0) {
        return;
      }
      const {
        totalFlow,
        dayFlow,
        commissionFlow,
        sevenDays,
        dayFlowShow,
        commissionFlowShow,
      } = this.dataObj;
      this.totalFlow = totalFlow.padStart(7, "0").split("");
      this.dayFlow = dayFlow;
      this.commissionFlow = commissionFlow;
      this.renderEchartsMethod(sevenDays, dayFlowShow, commissionFlowShow);
    },
    // 渲染echart
    renderEchartsMethod(sevenDays, dayFlowShow, commissionFlowShow) {
      let myChart = this.$echarts.init(document.getElementById("echarts2"));
      let option = {
        title: {
          show: false,
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          show: false,
        },
        toolbox: {
          show: false,
        },
        grid: {
          top: 20,
          right: 40,
          bottom: 25,
          left: 70,
        },
        xAxis: [
          {
            type: "category",
            data: sevenDays,
            boundaryGap: false,
            axisTick: {
              show: false,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#3486DA",
              },
            },
            axisLabel: {
              color: "#A2D4E6",
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLine: {
              show: true,
              lineStyle: {
                color: "#3486DA",
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: ["#162B5F"],
              },
            },
            axisLabel: {
              color: "#FFFFFF",
            },
          },
        ],
        series: [
          {
            name: "日均流水",
            type: "line",
            data: dayFlowShow,
            smooth: true,
            itemStyle: {
              color: "#8C78E9",
            },
            showSymbol: false,
            areaStyle: {
              opacity: 0.8,
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#8412EE",
                },
                {
                  offset: 1,
                  color: "rgba(76,36,84, 0)",
                },
              ]),
            },
          },
          {
            name: "伙伴权益",
            type: "line",
            data: commissionFlowShow,
            smooth: true,
            itemStyle: {
              color: "#05FFF6",
            },
            showSymbol: false,
            areaStyle: {
              opacity: 0.8,
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#05FFF6",
                },
                {
                  offset: 1,
                  color: "rgba(5, 255, 246, 0)",
                },
              ]),
            },
          },
        ],
      };
      myChart.setOption(option);
    },
  },
  watch: {
    dataObj() {
      this.getDataMethod();
    },
  },
};
</script>

<style lang="less" scoped>
.component_container {
  width: 100%;
  height: 100%;
  color: #fff;
  box-sizing: border-box;
}
.title_box {
  font-size: 24px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  padding-top: 15px;
  padding-left: 10px;
}
.num_box {
  width: 470px;
  height: 69px;
  margin: 24px auto 0;
  background: url("../assets/images/left/top-num.png");
  background-size: 100%;
  display: flex;
  align-items: center;
  padding-top: 4px;
  .name {
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
    margin-left: 31px;
    margin-right: 30px;
  }
  .num {
    width: 40px;
    height: 51px;
    text-align: center;
    font-size: 45px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #5fe0fe;
    line-height: 51px;
    text-shadow: 0px 4px 8px rgba(12, 77, 164, 0.6);
    margin-right: 6px;
    background: url("../assets/images/left/numBg.png");
    background-size: 100%;
  }
  span {
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
    margin-left: auto;
    margin-right: 18px;
  }
}
.content_box {
  display: flex;
  padding: 0 30px;
  margin-top: 12px;
  justify-content: space-between;
  .item {
    background-size: 100%;
    display: flex;
    img {
      width: 22px;
      height: 56px;
    }
    .right {
      .name {
        margin-top: 2px;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #f1eefd;
      }
      .num {
        font-size: 32px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #7e7ed5;
        margin-top: 10px;
        line-height: 24px;
        span {
          font-size: 16px;
        }
      }
    }
  }
}
.echarts_box {
  height: 180px;
  #echarts2 {
    width: 100%;
  }
}
</style>
