import LeftTop from "../../components/LeftTop";
import LeftCenter from "../../components/LeftCenter";
import LeftBottom from "../../components/LeftBottom";
import CenterTop from "../../components/CenterTop";
import CenterBootom from "../../components/CenterBootom";
import RightTop from "../../components/RightTop";
import RightCenter from "../../components/RightCenter";
import RightBottom from "../../components/RightBottom";
import {
  projectFund,
  projectPackage,
  activeBillboard,
  dataStatistics,
  projectDistribution,
  flowSituation,
  communityMerchantsBillboard,
  propertyPartners
} from '../../config/api'
export default {
  components: {
    LeftTop,
    LeftCenter,
    LeftBottom,
    CenterTop,
    CenterBootom,
    RightTop,
    RightCenter,
    RightBottom,
  },
  data() {
    return {
      // 筛选
      type: null,
      // 项目基金
      projectFundData: {},
      // 项目包
      projectPackageData: {},
      // 社创伙伴活跃榜
      partnerListData: {},
      // 数据统计
      statsData: {},
      // 项目分布
      projectDistributionData: {},
      // 流水情况
      flowSituationData: {},
      // 社区商家活跃榜
      merchantListData: {},
      // 物业伙伴榜
      propertyListData: {},
    };
  },
  mounted() {
    this.getAllMsg()
    // 15秒刷新一次所有数据
    setInterval(() => {
      this.getAllMsg()
    }, 15000)
  },
  methods: {
    // 改变筛选
    selectChange(type) {
      this.type = type
      this.getSelectMsg()
    },
    // 获取所有数据
    getAllMsg() {
      this.getData1()
      this.getData2()
      this.getData3()
      this.getData4()
      this.getData5()
      this.getData6()
      this.getData7()
      this.getData8()
    },
    // 获取筛选数据
    getSelectMsg() {
      this.getData3()
      this.getData6()
      this.getData7()
      this.getData8()
    },
    // 获取项目基金数据
    getData1() {
      this.postRequest(projectFund).then((res) => {
        this.projectFundData = res.data
      })
    },
    // 获取项目包数据
    getData2() {
      this.postRequest(projectPackage).then((res) => {
        this.projectPackageData = res.data
      })
    },
    // 获取社创伙伴活跃榜数据
    getData3() {
      this.postRequest(activeBillboard, {
        type: this.type
      }).then((res) => {
        this.partnerListData = res.data
      })
    },
    // 获取数据统计数据
    getData4() {
      this.postRequest(dataStatistics).then((res) => {
        this.statsData = res.data
      })
    },
    // 获取项目分布数据
    getData5() {
      this.postRequest(projectDistribution).then((res) => {
        this.projectDistributionData = res.data
      })
    },
    // 获取流水情况数据
    getData6() {
      this.postRequest(flowSituation, {
        type: this.type
      }).then((res) => {
        this.flowSituationData = res.data
      })
    },
    // 获取社区商家活跃榜数据
    getData7() {
      this.postRequest(communityMerchantsBillboard, {
        type: this.type
      }).then((res) => {
        this.merchantListData = res.data
      })
    },
    // 获取物业伙伴榜数据
    getData8() {
      this.postRequest(propertyPartners, {
        type: this.type
      }).then((res) => {
        this.propertyListData = res.data
      })
    },
  }
};