
<template>
  <div class="component_container">
    <div class="title_box">社区商家活跃榜</div>
    <div class="num_box">
      <div class="item" v-for="(item, index) in list" :key="index">
        <div class="title">
          <div class="name">TOP {{ index + 1 }} {{ item.merchantName }}</div>
          <div class="num">{{ item.salesVolume }}元</div>
        </div>
        <div class="rate">
          <div class="length" :style="{ width: list[index].width }"></div>
          <div class="drop"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CentetLeft1",
  props: {
    dataObj: {
      default: [],
    },
  },
  data() {
    return {
      list: [],
    };
  },
  watch: {
    dataObj() {
      this.getDataMethod();
    },
  },
  methods: {
    getDataMethod() {
      this.list = this.dataObj.map((item) => {
        item.width =
          (item.salesVolume / this.dataObj[0].salesVolume) * 100 + "%";
        return item;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.component_container {
  width: 100%;
  height: 100%;
  color: #fff;
  box-sizing: border-box;
}
.title_box {
  font-size: 24px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  padding-top: 15px;
  padding-left: 12px;
}
.num_box {
  padding: 30px 20px 0 40px;
  .item {
    background-size: 100%;
    margin-bottom: 15px;
    .title {
      display: flex;
      justify-content: space-between;
      .name {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #feffff;
      }
      .num {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #00ffff;
      }
    }
    .rate {
      margin-top: 6px;
      width: 100%;
      background: rgba(255, 255, 255, 0.12);
      height: 6px;
      display: flex;
      align-items: center;
      .length {
        height: 6px;
        border-radius: 6px;
        background: linear-gradient(90deg, #08b1ff 0%, #6bfff3 99%);
      }
      .drop {
        width: 14px;
        height: 14px;
        background: linear-gradient(90deg, #08b1ff 0%, #6bfff3 99%);
        border-radius: 50%;
        transform: translateX(-7px);
      }
    }
  }
}
</style>
