
<template>
  <div class="component_container">
    <div class="select">
      <el-select
        v-model="type"
        placeholder="请选择"
        :popper-append-to-body="false"
        @change="selectChange"
      >
        <el-option
          v-for="(item, index) in selectList"
          :key="index"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <img src="../assets/images/center/arrow.png" alt="" />
    </div>
    <div class="topBox">
      <div class="item">
        <div class="name">项目数量</div>
        <div class="num">{{ projectNum }}</div>
      </div>
      <div class="item">
        <div class="name">居民数量</div>
        <div class="num">{{ residentNum }}</div>
      </div>
      <div class="item item2">
        <div class="name">志愿者数量</div>
        <div class="num">{{ volunteerNum }}</div>
      </div>
    </div>
    <div class="centerBox">
      <div class="item item1">
        <div class="num">{{ companyNum }}</div>
        <div class="name">链接企业资源</div>
      </div>
      <div class="item item2">
        <div class="num">{{ foundationNum }}</div>
        <div class="name">链接基金会</div>
      </div>
      <div class="item item3">
        <div class="num">{{ orgNum }}</div>
        <div class="name">链接社创伙伴</div>
      </div>
      <div class="item item4">
        <div class="num">{{ communityNum }}</div>
        <div class="name">覆盖社区</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CentetLeft1",
  props: {
    dataObj: {
      default: {},
    },
  },
  data() {
    return {
      type: 0,
      selectList: [
        { name: "全部", id: 0 },
        { name: "钱景社区", id: 1 },
        { name: "洛阳路社工站", id: 2 },
      ],
      communityNum: "0",
      companyNum: "0",
      foundationNum: "0",
      orgNum: "0",
      projectNum: "0",
      residentNum: "0",
      volunteerNum: "0",
    };
  },
  watch: {
    dataObj() {
      this.getDataMethod();
    },
  },
  methods: {
    // 改变筛选
    selectChange() {
      this.$emit("change", this.type);
    },
    getDataMethod() {
      const {
        communityNum,
        companyNum,
        foundationNum,
        orgNum,
        projectNum,
        residentNum,
        volunteerNum,
      } = this.dataObj;
      this.communityNum = communityNum;
      this.companyNum = companyNum;
      this.foundationNum = foundationNum;
      this.orgNum = orgNum;
      this.projectNum = projectNum;
      this.residentNum = residentNum;
      this.volunteerNum = volunteerNum;
    },
  },
};
</script>

<style lang="less" scoped>
.component_container {
  width: 100%;
  height: 100%;
  color: #fff;
  box-sizing: border-box;
  overflow: hidden;
}
.select {
  width: 140px;
  height: 32px;
  position: relative;
  margin: 26px 0 0 4px;
  background: url("../assets/images/center/select.png");
  background-size: 100%;
  .el-select{
    display: block;
  }
  /deep/ .el-input__inner {
    background: none;
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #2cadff;
    border: none;
    height: 32px;
    line-height: 32px;
    .el-input {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #2cadff;
    }
  }
  /deep/ .el-select {
    width: 100%;
    height: 100%;
    .el-scrollbar__wrap {
      margin-right: -30px !important;
    }
    .el-select-dropdown {
      width: 86px;
      background: #fff;
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      left: 0 !important;
      margin-top: 0px !important;
      border: 0;
      border-radius: 0;
      background: #102e6a;
      .el-select-dropdown__item {
        font-size: 14px;
        font-weight: 400;
        color: #2cadff;
      }
      .el-select-dropdown__item.hover {
        background: #174086;
        color: #9fd3ff;
      }
    }
    .el-popper .popper__arrow {
      display: none;
    }
    .el-input__suffix {
      display: none;
    }
  }
  img {
    position: absolute;
    right: 17px;
    top: 10px;
    width: 9px;
    height: 11px;
    pointer-events: none;
  }
}
.topBox {
  padding: 23px 64px 18px;
  display: flex;
  justify-content: space-between;
  .item {
    width: 200px;
    height: 71px;
    background: url("../assets/images/center/center1.png");
    background-size: 100%;
    text-align: center;
    .name {
      font-size: 20px;
      font-weight: 400;
      color: #b8e8ff;
      transform: translateY(-10px);
    }
    .num {
      font-size: 36px;
      font-weight: bold;
      color: #02AAFA;
      margin-top: 5px;
      font-family: Source Han Sans CN;
    }
  }
  .item2 {
    width: 230px;
    background: url("../assets/images/center/center2.png");
    background-size: 100%;
  }
}
.centerBox {
  position: relative;
  .item {
    text-align: center;
    width: 195px;
    position: absolute;
    .num {
      width: 195px;
      height: 181px;
      background: url("../assets/images/center/numBg1.png");
      background-size: 100%;
      padding-top: 90px;
      font-size: 48px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #b8e8ff;
    }
    .name {
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
    }
  }
  .item1 {
    left: 20px;
    top: 78px;
  }
  .item2 {
    left: 200px;
    top: 0;
    .num {
      background: url("../assets/images/center/numBg2.png");
      background-size: 100%;
    }
  }
  .item3 {
    right: 207px;
    top: 0;
    .num {
      background: url("../assets/images/center/numBg3.png");
      background-size: 100%;
    }
  }
  .item4 {
    right: 16px;
    top: 61px;
    .num {
      background: url("../assets/images/center/numBg4.png");
      background-size: 100%;
    }
  }
}
</style>
