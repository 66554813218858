
<template>
  <div class="component_container">
    <div class="title_box">项目包</div>
    <div class="content_box">
      <div class="item">
        <div class="num">
          <span>{{ planAmount }}</span
          >个
        </div>
        <div class="name">数量</div>
      </div>
      <div class="item">
        <div class="num">
          <span>{{ transactionAmount }}</span
          >万
        </div>
        <div class="name">交易总额</div>
      </div>
    </div>
    <div class="num_box">
      <div
        class="item"
        v-for="(item, index) in bgi"
        :key="index"
        :style="{ backgroundImage: 'url(' + bgi[index].url + ')' }"
      >
        <div class="title">{{ item.typeName }}</div>
        <div class="rate">
          <div
            class="length"
            :style="{ background: bgi[index].color, width: bgi[index].width }"
          ></div>
        </div>
        <div class="num" :style="{ color: bgi[index].color }">
          {{ item.typeNum }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CentetLeft1",
  props: {
    dataObj: {
      default: {},
    },
  },
  data() {
    return {
      planAmount: 0,
      transactionAmount: 0,
      bgi: [
        {
          url: require("../assets/images/left/center1.png"),
          color: "#5AD7F5",
        },
        {
          url: require("../assets/images/left/center2.png"),
          color: "#9E6FF9",
        },
        {
          url: require("../assets/images/left/center3.png"),
          color: "#FAD573",
        },
      ],
    };
  },
  watch: {
    dataObj() {
      this.getDataMethod();
    },
  },
  methods: {
    getDataMethod() {
      if (Object.keys(this.dataObj).length == 0) {
        return;
      }
      const { planAmount, transactionAmount, typeList } = this.dataObj;
      this.planAmount = planAmount;
      this.transactionAmount = transactionAmount.slice(0, -1);
      typeList.forEach(({ typeName, typeNum }, index) => {
        this.bgi[index].typeName = typeName;
        this.bgi[index].typeNum = typeNum;
        this.bgi[index].width = `${(typeNum / typeList[0].typeNum) * 100}%`;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.component_container {
  width: 100%;
  height: 100%;
  color: #fff;
  box-sizing: border-box;
}
.title_box {
  font-size: 24px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  padding-top: 15px;
}
.content_box {
  display: flex;
  padding: 0 63px;
  margin-top: 35px;
  justify-content: space-between;
  .item {
    width: 160px;
    height: 106px;
    background: url("../assets/images/left/center-num.png");
    background-size: 100%;
    text-align: center;
    .num {
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
      span {
        font-size: 42px;
      }
    }
    .name {
      font-size: 17px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
      margin-top: 11px;
    }
  }
}
.num_box {
  padding: 4px 28px 0 20px;
  .item {
    width: 483px;
    height: 54px;
    background-size: 100%;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    padding: 0 20px 0 45px;
    .title {
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #f0f1f7;
      width: 80px;
    }
    .rate {
      margin-left: 40px;
      width: 180px;
      .length {
        height: 12px;
        border-radius: 6px;
      }
    }
    .num {
      font-size: 26px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      margin-left: auto;
    }
  }
}
</style>
