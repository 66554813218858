
<template>
  <div class="component_container">
    <div class="title_box">项目分布</div>
    <div class="echarts_box">
      <div id="echarts1" style="height: 100%"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CentetLeft1",
  props: {
    dataObj: {
      default: [],
    },
  },
  data() {
    return {};
  },
  mounted() {
    this.getDataMethod();
  },
  methods: {
    getDataMethod() {
      const { categoryName, projectNum } = this.dataObj;
      this.renderEchartsMethod(categoryName, projectNum);
    },
    // 渲染echart
    renderEchartsMethod(categoryName, projectNum) {
      let myChart = this.$echarts.init(document.getElementById("echarts1"));
      let option = {
        title: {
          show: false,
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          show: false,
        },
        toolbox: {
          show: false,
        },
        grid: {
          top: 20,
          right: 40,
          bottom: 40,
          left: 70,
        },
        xAxis: [
          {
            type: "category",
            data: categoryName,
            axisTick: {
              show: false,
              alignWithLabel: true,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#3486DA",
              },
            },
            axisLabel: {
              color: "#A2D4E6",
              formatter: function (params) {
                var newParamsName = ""; // 最终拼接成的字符串
                var paramsNameNumber = params.length; // 实际标签的个数
                var provideNumber = 2; // 每行能显示的字的个数
                var rowNumber = Math.ceil(paramsNameNumber / provideNumber); // 换行的话，需要显示几行，向上取整
                /**
                 * 判断标签的个数是否大于规定的个数， 如果大于，则进行换行处理 如果不大于，即等于或小于，就返回原标签
                 */
                // 条件等同于rowNumber>1
                if (paramsNameNumber > provideNumber) {
                  /** 循环每一行,p表示行 */
                  for (var p = 0; p < rowNumber; p++) {
                    var tempStr = ""; // 表示每一次截取的字符串
                    var start = p * provideNumber; // 开始截取的位置
                    var end = start + provideNumber; // 结束截取的位置
                    // 此处特殊处理最后一行的索引值
                    if (p == rowNumber - 1) {
                      // 最后一次不换行
                      tempStr = params.substring(start, paramsNameNumber);
                    } else {
                      // 每一次拼接字符串并换行
                      tempStr = params.substring(start, end) + "\n";
                    }
                    newParamsName += tempStr; // 最终拼成的字符串
                  }
                } else {
                  // 将旧标签的值赋给新标签
                  newParamsName = params;
                }
                //将最终的字符串返回
                return newParamsName;
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLine: {
              show: true,
              lineStyle: {
                color: "#3486DA",
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: ["#162B5F"],
              },
            },
            axisLabel: {
              color: "#FFFFFF",
            },
          },
        ],
        dataZoom: [
          {
            type: "inside", //slider表示有滑动块的，inside表示内置的
            show: true,
            xAxisIndex: [0],
            start: 0,
            end: 40,
            zoomOnMouseWheel: false,
          },
        ],
        series: [
          {
            type: "bar",
            data: projectNum,
            barWidth: 26,
            roam: false,
            itemStyle: {
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#00F6FF" },
                { offset: 1, color: "#02071A" },
              ]),
              barBorderRadius: [50, 50, 0, 0],
            },
          },
        ],
      };
      myChart.setOption(option);
    },
  },
  watch: {
    dataObj() {
      this.getDataMethod();
    },
  },
};
</script>

<style lang="less" scoped>
.component_container {
  width: 100%;
  height: 100%;
  color: #fff;
  box-sizing: border-box;
}
.title_box {
  font-size: 24px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  padding-top: 15px;
  padding-left: 220px;
}
.echarts_box {
  height: 250px;
  margin-top: 20px;
  #echarts1 {
    width: 100%;
  }
}
</style>
