<template>
  <div class="scorll_container">
    <div class="bgTop"></div>
    <div class="bgCenter"></div>
    <!-- 头部区域 -->
    <div class="header_box">社区运营看板</div>
    <div class="home_container">
      <div class="left-box">
        <div class="left-box-top">
          <LeftTop :dataObj="projectFundData"></LeftTop>
        </div>
        <div class="left-box-center">
          <LeftCenter :dataObj="projectPackageData"></LeftCenter>
        </div>
        <div class="left-box-bottom">
          <LeftBottom :dataObj="partnerListData"></LeftBottom>
        </div>
      </div>
      <div class="center-box">
        <div class="center-box-top">
          <CenterTop :dataObj="statsData" @change="selectChange"></CenterTop>
        </div>
        <div class="center-box-center">
        </div>
        <div class="center-box-bottom">
          <CenterBootom :dataObj="projectDistributionData"></CenterBootom>
        </div>
      </div>
      <div class="right-box">
        <div class="right-box-top">
          <RightTop :dataObj="flowSituationData"></RightTop>
        </div>
        <div class="right-box-center">
          <RightCenter :dataObj="merchantListData"></RightCenter>
        </div>
        <div class="right-box-bottom">
          <RightBottom :dataObj="propertyListData"></RightBottom>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mix from "./Home_mixins.js";
export default {
  mixins: [mix],
  data() {
    return {
      type: 0,
    };
  },
};
</script>

<style lang="less" scoped>
@import "./Home.less";
</style>
