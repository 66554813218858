
<template>
  <div class="component_container">
    <div class="title_box">社创伙伴活跃榜</div>
    <div class="num_box">
      <div class="item" v-for="(item, index) in list" :key="index">
        <img :src="bgi[index].url" v-if="index < 3" alt="" />
        <div class="title" v-else>{{ index + 1 }}</div>
        <div class="center">{{ item }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dataObj: {
      default: [],
    },
  },
  data() {
    return {
      list: [],
      bgi: [
        {
          url: require("../assets/images/left/bottom1.png"),
        },
        {
          url: require("../assets/images/left/bottom2.png"),
        },
        {
          url: require("../assets/images/left/bottom3.png"),
        },
      ],
    };
  },
  watch: {
    dataObj() {
      this.getDataMethod();
    },
  },
  methods: {
    getDataMethod() {
      this.list = this.dataObj;
    },
  },
};
</script>

<style lang="less" scoped>
.component_container {
  width: 100%;
  height: 100%;
  color: #fff;
  box-sizing: border-box;
}
.title_box {
  font-size: 24px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  padding-top: 15px;
}
.num_box {
  padding: 4px 28px 0 0;
  .item {
    width: 483px;
    background-size: 100%;
    margin-top: 30px;
    display: flex;
    align-items: center;
    padding-left: 40px;
    img {
      width: 17px;
      height: 20px;
      display: block;
      margin-right: 18px;
    }
    .title {
      width: 17px;
      text-align: center;
      margin-right: 18px;
      padding-top: 2px;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #93DCFE;
    }
    .center {
      font-size: 16px;
      font-weight: 400;
      color: #93dcfe;
    }
  }
}
</style>
