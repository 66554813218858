import Home from "../views/Home/Home.vue";
const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
];

export default routes;
