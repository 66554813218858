
<template>
  <div class="component_container">
    <div class="title_box">项目资金</div>
    <div class="num_box">
      <div class="name">项目资金</div>
      <div class="num" v-for="(item, index) in totalProjectFund" :key="index">
        {{ item }}
      </div>
      <span>万</span>
    </div>
    <div class="content_box">
      <div class="item">
        <div class="left">
          <div class="name">政府资金</div>
          <div class="num"><span>￥</span>{{ govFund }}万</div>
        </div>
        <div class="right">{{ govFundPer }}<span>%</span></div>
      </div>
      <div class="item">
        <div class="left">
          <div class="name">公募资金</div>
          <div class="num num2"><span>￥</span>{{ publicRundRasing }}万</div>
        </div>
        <div class="right right2">{{ publicRundRasingPer }}<span>%</span></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CentetLeft1",
  props: {
    dataObj: {
      default: {},
    },
  },
  data() {
    return {
      totalProjectFund: "0000000",
      govFund: 0,
      govFundPer: 0,
      publicRundRasing: 0,
      publicRundRasingPer: 0,
    };
  },
  watch: {
    dataObj() {
      this.getDataMethod();
    },
  },
  methods: {
    getDataMethod() {
      if (Object.keys(this.dataObj).length == 0) {
        return;
      }
      const {
        totalProjectFund,
        govFund,
        govFundPer,
        publicRundRasing,
        publicRundRasingPer,
      } = this.dataObj;
      this.totalProjectFund = totalProjectFund.padStart(7, "0").split("");
      this.govFund = govFund;
      this.govFundPer = govFundPer * 100;
      this.publicRundRasing = publicRundRasing;
      this.publicRundRasingPer = publicRundRasingPer * 100;
    },
  },
};
</script>

<style lang="less" scoped>
.component_container {
  width: 100%;
  height: 100%;
  color: #fff;
  box-sizing: border-box;
}
.title_box {
  font-size: 24px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  padding-top: 15px;
  padding-right: 10px;
}
.num_box {
  width: 471px;
  height: 69px;
  margin: 24px auto 0;
  background: url("../assets/images/left/top-num.png");
  background-size: 100%;
  display: flex;
  align-items: center;
  padding-top: 4px;
  .name {
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
    margin-left: 27px;
    margin-right: 26px;
  }
  .num {
    width: 40px;
    height: 51px;
    text-align: center;
    font-size: 45px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #5fe0fe;
    line-height: 51px;
    text-shadow: 0px 4px 8px rgba(12, 77, 164, 0.6);
    margin-right: 6px;
    background: url("../assets/images/left/numBg.png");
    background-size: 100%;
  }
  span {
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
    margin-left: auto;
    margin-right: 16px;
  }
}
.content_box {
  display: flex;
  padding: 0 34px;
  margin-top: 20px;
  justify-content: space-between;
  .item {
    width: 220px;
    height: 100px;
    background: url("../assets/images/left/top-bg.png");
    background-size: 100%;
    display: flex;
    padding-top: 10px;
    position: relative;
    .left {
      padding: 0 10px 0 18px;
      .name {
        margin-top: 12px;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
      }
      .num {
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #9e6ff9;
        margin-top: 13px;
        span {
          font-size: 16px;
        }
      }
      .num2 {
        color: #5fe0fe;
      }
    }
    .right {
      position: absolute;
      right: 10px;
      top: 10px;
      padding-top: 25px;
      width: 102px;
      height: 82px;
      background: url("../assets/images/left/top1.png");
      background-size: 100%;
      text-align: center;
      font-size: 19px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
      span {
        font-size: 14px;
      }
    }
    .right2 {
      background: url("../assets/images/left/top2.png");
      background-size: 100%;
    }
  }
}
</style>
