import Vue from 'vue'
import axios from "axios"
import qs from 'qs'
axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded'; // 设置默认的请求头的Content-Type

// 请求拦截器
axios.interceptors.request.use(config => {
  config.headers.appId = 10
  if (Object.prototype.toString.call(config.data) !== '[object FormData]') {
    config.data = qs.stringify(config.data);
  }
  return config
})

// 响应拦截器
axios.interceptors.response.use(response => response.data)

/**
 * @description: 封装get请求
 * @param {string} url 请求的路径
 * @param {object} data 发送的参数
 * @return {Promise} 返回promise对象
 */
export const getRequest = (url, data) => axios.get(url, { params: data }).catch(res => res)

/**
 * @description: 封装post请求
 * @param {string} url 请求的路径
 * @param {object} data 发送的参数
 * @return {Promise} 返回promise对象
 */
export const postRequest = (url, data) => axios.post(url, data).catch(res => res)


/**
 * @description 封装获取json请求
 * @param {string} url 请求的路径 
 * @returns 返回promise对象
 */
export const getJson = (url) => axios({
  method: 'get',
  url: url,
  dataType: "json",
  crossDomain: true,
  cache: false
}).catch(res => res)

Vue.prototype.getRequest = getRequest
Vue.prototype.postRequest = postRequest
Vue.prototype.getJson = getJson